import React, { useContext, useEffect, useMemo } from 'react'
import { AppContext } from 'components/AppContext'
import GuidedTour, { Step } from 'components/GuidedTour'
import {
  Badge,
  Button,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import ContentHeader from 'components/ContentHeader'
import TodoList, { TodoListItem } from 'components/TodoList'
import { omit } from 'lodash'
import CompletedTasksDrawer from 'components/CompletedTasksDrawer'
import useModalProps from 'hooks/useModalProps'
import useTimeOfDay from 'hooks/useTimeOfDay'
import { PatientTodoStatus } from '__generated__/graphql'
import LoadingBackdrop from 'components/LoadingBackdrop'
import StoryCard from 'components/StoryCard'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useOnboarding from 'hooks/useOnboarding'
import ResponsiveContainer from 'components/ResponsiveContainer'
import { useTranslation } from 'react-i18next'

const { ACTIVE, COMPLETE } = PatientTodoStatus

export default function HomePage() {
  const { t } = useTranslation()
  useDocumentTitle('')
  const { patient, featureFlags, fetchTodos, todosLoading, todos } =
    useContext(AppContext)
  const { storyPlayerOpen } = useContext(StoryPlayerContext)
  const { onboardingCompleted } = useOnboarding()
  const logoSrc = patient?.account?.logoSrc
  const timeOfDay = useTimeOfDay()
  const {
    modalProps: completedTasksDialogProps,
    setOpen: setCompletedTasksDrawerOpen,
  } = useModalProps()
  const todoListItems = (todos ?? []).map(
    (todo): TodoListItem => ({
      category: todo.category,
      chip: todo.metadata?.chip,
      description: todo.description,
      href: todo.url,
      id: todo.id,
      status: todo.status,
      title: todo.title,
    }),
  )
  const loading = !storyPlayerOpen && todosLoading
  const activeTodos = todoListItems.filter(
    (item) => item.status === ACTIVE,
  )
  const completedTasks = todoListItems
    .filter((todo) => todo.status === COMPLETE)
    .map((item) => omit(item, ['chip']))
  const greeting = {
    afternoon: t('index.greeting.afternoon', {
      name: patient?.firstName,
    }),
    evening: t('index.greeting.evening', {
      name: patient?.firstName,
    }),
    morning: t('index.greeting.morning', {
      name: patient?.firstName,
    }),
    night: t('index.greeting.night', { name: patient?.firstName }),
  }
  const title =
    loading && !activeTodos.length
      ? ''
      : activeTodos.length
        ? t('index.title.letsGetStarted')
        : t('index.title.allDone')
  const { patientAppCarePlan } = featureFlags

  const tourGuideSteps = useMemo<Step[]>(() => {
    const defaultTourGuideSteps: Step[] = [
      {
        content: t('index.tourGuideSteps.homePage'),
        disableScrolling: true,
        placement: 'center',
        target: 'body',
      },
      {
        content: t('index.tourGuideSteps.useMenu'),
        disableScrolling: true,
        styles: {
          tooltipContent: {
            textAlign: 'left',
          },
        },
        target: `[data-testid='nav-bar.menu']`,
      },
    ]
    return [
      ...defaultTourGuideSteps,
      patientAppCarePlan && {
        content: t('index.tourGuideSteps.quickAccess'),
        disableScrolling: true,
        target: `[data-testid='nav-bar.care-plans']`,
      },
    ].filter(Boolean)
  }, [patientAppCarePlan, t])

  // Refresh todos after story player is closed.
  useEffect(() => {
    if (!storyPlayerOpen) {
      fetchTodos()
    }
  }, [fetchTodos, storyPlayerOpen])

  return (
    <>
      <GuidedTour
        id="home"
        steps={tourGuideSteps}
        open={onboardingCompleted && !storyPlayerOpen}
      />
      <ResponsiveContainer sx={{ gap: 2, pb: 12, pt: 2 }}>
        <ContentHeader
          subtitle={greeting[timeOfDay]}
          title={title}
          logoSrc={logoSrc}
        />
        <Stack>
          <Typography
            variant="h4"
            sx={{
              color: 'text.secondary',
              p: 2,
            }}
          >
            <Badge badgeContent={activeTodos.length} color="primary">
              {t('index.toDos')}
            </Badge>
          </Typography>
          <LoadingBackdrop open={loading} invisible />
          <Stack
            gap={1}
            sx={{
              alignItems: 'stretch',
              justifyContent: 'center',
              mb: 6,
              px: {
                sm: 2,
                xs: 0,
              },
            }}
          >
            {todos && (
              <TodoList items={activeTodos}>
                {!!completedTasks.length && (
                  <Button
                    variant="text"
                    size="large"
                    color="secondary"
                    sx={{ width: 1 }}
                    onClick={() => setCompletedTasksDrawerOpen(true)}
                  >
                    {t('index.viewCompleted')}
                  </Button>
                )}
              </TodoList>
            )}
            {!todos && todosLoading && (
              <Stack
                sx={{
                  alignItems: 'stretch',
                  color: 'text.secondary',
                  justifyContent: 'center',
                  my: -1,
                }}
              >
                <StoryCard
                  id="todo-loading"
                  title={<Skeleton width={120} />}
                  subtitle={<Skeleton width="90%" />}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
        <CompletedTasksDrawer
          {...completedTasksDialogProps}
          items={completedTasks}
        />
      </ResponsiveContainer>
    </>
  )
}
